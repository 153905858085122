import React, { useEffect, useState, createContext, useContext, useRef } from 'react'
import { useLocation, Link, useHistory } from 'react-router-dom';
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, set, useForm } from 'react-hook-form';
import TrabajadorButtonGroup from 'components/blocks/ManagementNewForms/ButtonGroups/TrabajadorButtonGroup';
import InputPattern from 'components/blocks/ManagementNewForms/Inputs/InputPattern';
import InputDate from 'components/blocks/Delta/Inputs/InputDate';
import InputIBAN from 'components/blocks/ManagementNewForms/Inputs/InputIBAN';
import InputUpload from 'components/blocks/ManagementNewForms/Inputs/InputUpload';
import ArrayFieldUploads from 'components/blocks/ManagementNewForms/ButtonGroups/ArrayFieldUploads';
import AccordionForm from 'components/blocks/ManagementNewForms/Accordion/AccordionForm';
import InputRegimenSeguridadSocial from 'components/blocks/ManagementNewForms/Inputs/InputRegimenSeguridadSocial';
import AgreementType from 'components/blocks/Delta/Inputs/AgreementType';
import InputCausaDelPago from 'components/blocks/ManagementNewForms/Inputs/InputCasuaDelPago';
import InputContigenciasPagoDirecto from 'components/blocks/ManagementNewForms/Inputs/InputContingenciasPagoDirecto';
import { get } from 'http';
import InputArchivos from 'components/blocks/ManagementNewForms/Inputs/InputArchivos';
import JSZip from 'jszip';
import EntornoLaboral from 'components/blocks/ManagementSheets/Comun/EntornoLaboral';
import useUpdateDirectPaymentFormItByID from 'services/record/useUpdateDirectPaymentFormItByID';
import Loader from 'components/blocks/Loader/Loader';
import useCreateNewDirectPaymentFormIt from 'services/record/useCreateNewDirectPaymentFormIt';
import axios from 'axios';
const jwtDecode = require('jwt-decode');





interface Documentacion {
    Id: string;
    DocumentoDigitalId?: number;
    TipoDocumento?: number;
    NombrePortal?: string;
    NombreStorage?: string;
    Container?: string;
    Extension?: string;
    FechaPresentacion?: Date;
    Uri?: string;
    SasUri?: string;
    Observaciones?: string;
    Nota?: string;
}

interface DocumentacionEnvio {
    Id: string;
    TipoDocumento: number;
    NombrePortal: string;
    Nombre: string;
    Extension: string;
    Base64: string;
    Nota?: string;
}

interface SolicitudPagoDirecto {
    Id: string;
    UsuarioId: string;
    PersonaId: number;
    PrestacionId: string;
    EstadoFormulario: number;
    PagoDirectoId: number;
    EntornoLaboralId: number;
    ExpedienteId: number;
    AceptacionSolicitud?: boolean;
    FechaAceptacionSolicitud: string;
    Observaciones: string;
    Nombre: string;
    Apellido1: string;
    Apellido2: string;
    IPF: string;
    TipoIPF: number;
    FechaNacimiento: string;
    TelefonoFijo: string;
    TelefonoMovil: string;
    Email: string;
    NumeroSeguridadSocial: string;
    PorcentajeIRPFVoluntario: number;
    Domicilio: string;
    CodigoPostal: string;
    LocalidadCodigo: string;
    ProvinciaCodigo: string;
    RazonSocial: string;
    CodigoCuentaCotizacion: string;
    RegimenSeguridadSocialCodigo: number;
    TipoContratoCodigo: string;
    TipoContratoDescripcion: string;
    FechaBajaMedica: string;
    Contingencia: number;
    CausaPagoDirecto: number;
    NumeroCuentaConIBAN: string;
    Documentacion: (DocumentacionEnvio | Documentacion)[];
}



export const SolicitudPagoDirectoIncapacidadTemporal: React.FC<{dataUser: SolicitudPagoDirecto}> = ({dataUser}) => {

    const location = useLocation();
    const history = useHistory();
    const title = "Solicitud de pago directo por incapacidad temporal"
    const breadcrumbs = [""];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, control } = formMethods;
    const { updateSolicitudPagoDirectoById,
        loadingUpdateSolicitudPagoDirectoById,
        successUpdateSolicitudPagoDirectoById,
        errorUpdateSolicitudPagoDirectoById,
        dataUpdateSolicitudPagoDirectoById } = useUpdateDirectPaymentFormItByID();

    const {createDirectPaymentFormIt, dataDirectPaymentFormIt, loadingDirectPaymentFormIt, successDirectPaymentFormIt, errorDirectPaymentFormIt} = useCreateNewDirectPaymentFormIt();

    const convertFileToBase64 = async (file: File): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (reader.result) {
                    const result = reader.result as string;

                    const base64Data = result.split(',')[1];
                    resolve(base64Data && base64Data);
                } else {

                    resolve(null);
                }
            };
            reader.onerror = (error) => {
                console.error(`Error al leer el archivo ${file.name}:`, error);
                reject(error);
            };
        });
    };
    const createZipFile = async (files: File[]): Promise<string> => {
        const zip = new JSZip();

        for (const file of files) {
            const base64 = await convertFileToBase64(file);
            if (base64) {
                zip.file(file.name, base64, { base64: true });
            } else {
                console.error(`El archivo ${file.name} está vacío o no se pudo leer.`);
            }
        }

        const zipContent = await zip.generateAsync({ type: "base64" });
        return zipContent;
    };

    const [aceptarPagoDir, setAceptarPagoDir] = useState(true);
    const watchWorkerContractCode = watch("EntornoLaboral.TipoContrato");
    const watchCausaPago = watch("CausaPagoDirecto.CausaPago");

    

    const solicitudPagoDirectoEjemplo: SolicitudPagoDirecto = {
        Id: "971907c4-0887-44ea-f683-08dd0a254328",
        UsuarioId: "d231f626-f2c1-447c-85a2-08db6d850c38",
        PrestacionId: "dd8faa50-1bc3-4d1e-ee86-08dbb5e6871f",
        PersonaId: 80677,
        PagoDirectoId: 1171,
        ExpedienteId: 1345543,
        EntornoLaboralId: 342,
        EstadoFormulario: 0,
        FechaAceptacionSolicitud: null,
        Observaciones: null,
        Nombre: "Robinson",
        Apellido1: "GOMEZ DE AGUERO ",
        Apellido2: "Rodrigiez",
        IPF: "03804398Z",
        TipoIPF: 1,
        FechaNacimiento: "2001-09-02T00:00:00",
        TelefonoFijo: null,
        TelefonoMovil: null,
        Email: null,
        NumeroSeguridadSocial: "450036959550",
        PorcentajeIRPFVoluntario: 12.30,
        Domicilio: null,
        CodigoPostal: null,
        LocalidadCodigo: null,
        ProvinciaCodigo: null,
        RazonSocial: "OTRA RAZON SOCIAL",
        CodigoCuentaCotizacion: null,
        RegimenSeguridadSocialCodigo: 1,
        TipoContratoCodigo: "100",
        TipoContratoDescripcion: null,
        FechaBajaMedica: "2024-08-11T23:21:39.5318256",
        Contingencia: 2,
        CausaPagoDirecto: 3,
        NumeroCuentaConIBAN: "ES6701826673610201541551",
        Documentacion: [
            {
                Id: "1f9e6fb6-4e30-43d4-97de-08dd0a25433a",
                DocumentoDigitalId: 20,
                TipoDocumento: 1,
                NombrePortal: "DNI/NIE/Pasaporte",
                NombreStorage: null,
                Container: null,
                Extension: null,
                FechaPresentacion: null,
                Uri: null,
                SasUri: null,
                Nota: "Debe adjuntar el reverso y el anverso del documento"
            },
            {
                Id: "7e5816c5-9929-4907-97df-08dd0a25433a",
                DocumentoDigitalId: 21,
                TipoDocumento: 99,
                NombrePortal: "Otros",
                NombreStorage: null,
                Container: null,
                Extension: null,
                FechaPresentacion: null,
                Uri: null,
                SasUri: null
            }
        ]
    };
   
    const datosUsuario =
    {
        Acepto: true,
        DatosPersonales: {
            SolicitanteNombre: dataUser?.Nombre,
            SolicitanteApellido1: dataUser?.Apellido1,
            SolicitanteApellido2: dataUser?.Apellido2,
            SolicitanteIPF: dataUser?.IPF,
            SolicitanteIPFTipo: dataUser?.TipoIPF,
            SolicitanteSeguridadSocial: dataUser?.NumeroSeguridadSocial,
            SolicitanteEmail: dataUser?.Email,
            SolicitanteTelefono: dataUser?.TelefonoMovil,
            SolicitanteFijo: dataUser?.TelefonoFijo,
            SolicitanteFechaNacimiento: dataUser?.FechaNacimiento,
            SolicitanteDomicilio: dataUser?.Domicilio,
            SolicitanteCodigoPostal: dataUser?.CodigoPostal,
            SolicitanteProvincia: dataUser?.ProvinciaCodigo,
            IRPFVoluntario: dataUser?.PorcentajeIRPFVoluntario,
            SolicitanteComunidadCodigo: dataUser?.LocalidadCodigo,
        },
        EntornoLaboral: {
            PrestacionRazonSocial: dataUser?.RazonSocial,
            PrestacionCCC: dataUser?.CodigoCuentaCotizacion,
            RegimenSeguridadSocial: dataUser?.RegimenSeguridadSocialCodigo,
            TipoContrato: dataUser?.TipoContratoCodigo,
        },
        CausaPagoDirecto: {
            PrestacionFechaBajaMedica: dataUser?.FechaBajaMedica,
            Contingencias: dataUser?.Contingencia,
            CausaPago: dataUser?.CausaPagoDirecto,
        },
        DatosBancarios: {
            SolicitanteIBAN: dataUser?.NumeroCuentaConIBAN
        }
    };

    

    const onSubmit = async (data: any) => {

        //console.log(data)

        const documentosEnviados: DocumentacionEnvio[] = await Promise.all(data.Archivo.map(async (Ar) => {
            const base64 = Ar?.file.length > 1
                ? await createZipFile(Ar?.file)
                : await convertFileToBase64(Ar?.file[0]);
        
            return {
                id: Ar.id,
                tipoDocumento: Ar.tipo,
                nombrePortal: Ar.nombrePortal,
                nombre: Ar?.file[0]?.name,
                extension: Ar?.file.length > 1 ? 'zip' : Ar?.file[0]?.name.split('.').pop(),
                base64: base64, // Ahora esto es una cadena de texto base64 resuelta
            };
        }));
        

        const solicitudPagoDirecto: SolicitudPagoDirecto = {
            Id: dataUser?.Id,
            UsuarioId: dataUser?.UsuarioId,
            PrestacionId: dataUser?.PrestacionId,
            PersonaId: dataUser?.PersonaId,
            EntornoLaboralId: dataUser?.EntornoLaboralId,
            ExpedienteId: dataUser?.ExpedienteId,
            PagoDirectoId: dataUser?.PagoDirectoId,
            EstadoFormulario: dataUser?.EstadoFormulario,
            FechaAceptacionSolicitud: new Date().toLocaleString(),
            AceptacionSolicitud: true,
            Observaciones: dataUser?.Observaciones,
            Nombre: data.DatosPersonales.SolicitanteNombre, // SolicitanteNombre
            Apellido1: data.DatosPersonales.SolicitanteApellido1, // SolicitanteApellido1
            Apellido2: data.DatosPersonales.SolicitanteApellido2, // SolicitanteApellido2
            IPF: data.DatosPersonales.SolicitanteIPF, // SolicitanteIPF
            TipoIPF: parseInt(data.DatosPersonales.SolicitanteIPFTipo), // SolicitanteIPFTipo
            FechaNacimiento: data.DatosPersonales.SolicitanteFechaNacimiento, // SolicitanteFechaNacimiento
            TelefonoFijo: data.DatosPersonales.SolicitanteTelefonoFijo, // SolicitanteTelefonoFijo
            TelefonoMovil: data.DatosPersonales.SolicitanteTelefonoMovil, // SolicitanteTelefonoMovil
            Email: data.DatosPersonales.SolicitanteEmail, // SolicitanteEmail
            NumeroSeguridadSocial: data.DatosPersonales.SolicitanteSeguridadSocial, // SolicitanteSeguridadSocial
            PorcentajeIRPFVoluntario: parseInt(data.DatosPersonales.IRPFVoluntario), // IRPFVoluntario
            Domicilio: data.DatosPersonales.SolicitanteDomicilio, // SolicitanteDomicilio
            CodigoPostal: data.DatosPersonales.SolicitanteCodigoPostal, // SolicitanteCodigoPostal
            LocalidadCodigo: data.DatosPersonales.SolicitanteComunidadCodigo, // SolicitanteComunidadCodigo
            ProvinciaCodigo: data.DatosPersonales.SolicitanteProvincia, // SolicitanteProvincia
            RazonSocial: data.EntornoLaboral.PrestacionRazonSocial, // PrestacionRazonSocial
            CodigoCuentaCotizacion: data.EntornoLaboral.PrestacionCCC, // PrestacionCCC
            RegimenSeguridadSocialCodigo: parseInt(data.EntornoLaboral.RegimenSeguridadSocial), // RegimenSeguridadSocial
            TipoContratoCodigo: data.EntornoLaboral.TipoContrato, // TipoContrato
            TipoContratoDescripcion: data.EntornoLaboral.TipoContratoDescripcion, // TipoContratoDescripcion
            FechaBajaMedica: data.CausaPagoDirecto.PrestacionFechaBajaMedica, // PrestacionFechaBajaMedica
            Contingencia: parseInt(data.CausaPagoDirecto.Contingencias), // Contingencias
            CausaPagoDirecto: parseInt(data.CausaPagoDirecto.CausaPago), // CausaPago
            NumeroCuentaConIBAN: data.DatosBancarios.SolicitanteIBAN, // SolicitanteIBAN
            Documentacion: documentosEnviados,

        };
        
        createDirectPaymentFormIt(solicitudPagoDirecto.UsuarioId, solicitudPagoDirecto.PrestacionId, solicitudPagoDirecto);
        console.log("DATA: " + dataDirectPaymentFormIt)
        console.log("ERRROR: " + errorDirectPaymentFormIt)
        //updateSolicitudPagoDirectoById(SolicitudPagoDirecto.usuarioId, SolicitudPagoDirecto.prestacionId, SolicitudPagoDirecto.pagoDirectoId, solicitudPagoDirecto);
        //updateSolicitudPagoDirectoById('', '' , '', solicitudPagoDirecto);
        
       console.log(solicitudPagoDirecto);
    };

    const AceptarSolicitud = () => {

        setAceptarPagoDir(!aceptarPagoDir);
    }



    return (
        <>

            {/* <PageMenu title={title} >
                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>
            </PageMenu> */}

            {(loadingUpdateSolicitudPagoDirectoById || loadingDirectPaymentFormIt) && <Loader activeLoader={true} label="Cargando Formulario"></Loader>}

            <FormProvider {...formMethods}>
                <div className=''></div>

                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className="row mb-4">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="" disabled={false}>


                                <div className='paragraph text-info mb-3 col-12'>Solicito el inicio del Pago Directo por Incapacidad Temporal, y por tanto declaro bajo mi responsabilidad que son ciertos todos los datos que consigno en dicha solicitud y quedo enterado de la obligación de comunicar a la Mutua cualquier variación de los datos en ella expresados, que pudieran producirse durante la percepción de la prestación de Incapacidad Temporal.

                                </div>
                                <div className='ml-5 form-check mb-4 col-12'>
                                    <input className='form-check-input' type='checkbox' id='Acepto' {...register('Acepto', { onChange: AceptarSolicitud })} />
                                    <label className='form-check-label ' htmlFor='Acepto'>Acepto la información declarada.</label>
                                </div>
                                <div className=' mb-4 col-12' style={{ display: aceptarPagoDir ? 'none' : 'block' }}>
                                    <div className='paragraph text-info col-12'>
                                        {"Aceptado el: " + new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date())}

                                    </div>
                                    <div className='paragraph text-info  col-12'>
                                        {/* {"Datos de Sesión del Usuario: " + jwtDecode(localStorage.getItem("token")).extension_id} */}

                                    </div>
                                </div>


                            </FieldsetContainer>
                        </div>
                    </div>
                    <AccordionForm title={'Datos Personales'} expandedInit={true} nombreError='DatosPersonales' >

                        <TrabajadorButtonGroup
                            errors={errors}
                            register={register}
                            label={'Datos Personales'}
                            setValue={setValue}
                            watch={watch}
                            acronimo='DatosPersonales.Solicitante'
                            nacimiento={true}
                            email={true}
                            telefono={true}
                            seguridadSocial={true}
                            defaultValueNombre={datosUsuario.DatosPersonales.SolicitanteNombre ? datosUsuario.DatosPersonales.SolicitanteNombre : ''}
                            defaultValueApellido1={datosUsuario.DatosPersonales.SolicitanteApellido1 ? datosUsuario.DatosPersonales.SolicitanteApellido1 : ''}
                            defaultValueApellido2={datosUsuario.DatosPersonales.SolicitanteApellido2 ? datosUsuario.DatosPersonales.SolicitanteApellido2 : ''}
                            defaultValueIPF={datosUsuario.DatosPersonales.SolicitanteIPF ? datosUsuario.DatosPersonales.SolicitanteIPF : ''}
                            defaultValueTipoIPF={datosUsuario.DatosPersonales.SolicitanteIPFTipo ? datosUsuario.DatosPersonales.SolicitanteIPFTipo.toString() : ''}
                            defaultValueSeguridadSocial={datosUsuario.DatosPersonales.SolicitanteSeguridadSocial ? datosUsuario.DatosPersonales.SolicitanteSeguridadSocial : ''}
                            defaultValueEmail={datosUsuario.DatosPersonales.SolicitanteEmail ? datosUsuario.DatosPersonales.SolicitanteEmail : ''}
                            defaultValueTelefonoMovil={datosUsuario.DatosPersonales.SolicitanteTelefono ? datosUsuario.DatosPersonales.SolicitanteTelefono : ''}
                            defaultValueTelefonoFijo={datosUsuario.DatosPersonales.SolicitanteFijo ? datosUsuario.DatosPersonales.SolicitanteFijo : ''}
                            defaultValueFechaNacimiento={datosUsuario.DatosPersonales.SolicitanteFechaNacimiento ? new Date(datosUsuario.DatosPersonales.SolicitanteFechaNacimiento) : null}
                            disableFechaNacimiento={true}
                            defaultValueCP={datosUsuario.DatosPersonales.SolicitanteCodigoPostal ? datosUsuario.DatosPersonales.SolicitanteCodigoPostal : ''}
                            defaultValueDomicilio={datosUsuario.DatosPersonales.SolicitanteDomicilio ? datosUsuario.DatosPersonales.SolicitanteDomicilio : ''}
                            defaultValueProvincia={datosUsuario.DatosPersonales.SolicitanteProvincia ? datosUsuario.DatosPersonales.SolicitanteProvincia : ''}
                            defaultValueMunicipio={datosUsuario.DatosPersonales.SolicitanteComunidadCodigo ? datosUsuario.DatosPersonales.SolicitanteComunidadCodigo : ''}
                            disableProvincia={true}
                            disableMunicipio={true}
                        >
                            <InputPattern
                                id="DatosPersonales.IRPFVoluntario"
                                name="DatosPersonales.IRPFVoluntario"
                                label="Tipo IRPF Voluntario (%)"
                                placeholder="Solo números"
                                xl={12}
                                md={12}
                                errors={errors}
                                register={register}
                                pattern={/^[0-9.,]+$/}
                                patternMessage='Solo se permiten valores numericos'
                                defaultValue={datosUsuario.DatosPersonales.IRPFVoluntario ? datosUsuario.DatosPersonales.IRPFVoluntario.toString() : ''}
                                disabled={false}
                            />

                        </TrabajadorButtonGroup>


                    </AccordionForm>


                    <AccordionForm title={'Entornos Laborales'} expandedInit={false} nombreError='EntornoLaboral' >
                        <div className="row">
                            <div className="col-xl-12">
                                <FieldsetContainer legend="Datos de la Prestación" disabled={false}>
                                    <InputPattern
                                        id="EntornoLaboral.PrestacionRazonSocial"
                                        name="EntornoLaboral.PrestacionRazonSocial"
                                        label="Nombre o razón social de la empresa"
                                        placeholder=""
                                        xl={6}
                                        md={6}
                                        errors={errors}
                                        register={register}
                                        defaultValue={datosUsuario.EntornoLaboral.PrestacionRazonSocial ? datosUsuario.EntornoLaboral.PrestacionRazonSocial : ''}
                                    />
                                    <InputPattern
                                        id="EntornoLaboral.PrestacionCCC"
                                        name="EntornoLaboral.PrestacionCCC"
                                        label="Código Cuenta Cotización de la empresa"
                                        placeholder=""
                                        pattern={/^[0-9]+$/}
                                        patternMessage='Solo se permiten valores numericos'
                                        defaultValue={datosUsuario.EntornoLaboral.PrestacionCCC ? datosUsuario.EntornoLaboral.PrestacionCCC : ''}
                                        xl={6}
                                        md={6}
                                        errors={errors}
                                        register={register}


                                    />

                                    <InputRegimenSeguridadSocial
                                        errors={errors}
                                        register={register}
                                        defaultValue={datosUsuario.EntornoLaboral.RegimenSeguridadSocial ? datosUsuario.EntornoLaboral.RegimenSeguridadSocial.toString() : ''}
                                        name={'EntornoLaboral.RegimenSeguridadSocial'}
                                        disabled={datosUsuario.EntornoLaboral.RegimenSeguridadSocial ? true : false}
                                    />
                                    <div className="col-12">
                                        <AgreementType
                                            errors={errors}
                                            register={register}
                                            setValue={setValue}
                                            watchWorkerContractCode={watchWorkerContractCode}
                                            idCode="EntornoLaboral.TipoContrato"
                                            idDescription="EntornoLaboral.TipoContratoDescripcion"
                                            req='Falta especificar el tipo de contrato'
                                            defaultValue={datosUsuario.EntornoLaboral.TipoContrato ? datosUsuario.EntornoLaboral.TipoContrato : null}
                                            disabled={datosUsuario.EntornoLaboral.TipoContrato ? true : false}
                                        />
                                    </div>


                                </FieldsetContainer>
                            </div>
                        </div>
                    </AccordionForm>











                    <AccordionForm title={'Causa del Pago Directo'} expandedInit={false} nombreError='CausaPagoDirecto'>
                        <div className="row">
                            <div className="col-xl-12">
                                <FieldsetContainer legend="" disabled={false}>
                                    <div className="col-xl-12 col-md-12">
                                        <InputDate
                                            id="CausaPagoDirecto.PrestacionFechaBajaMedica"
                                            name="CausaPagoDirecto.PrestacionFechaBajaMedica"
                                            label="Fecha de Baja Médica"
                                            maxDate={new Date(Date.now())}
                                            placeholder=""
                                            errors={errors}
                                            register={register}
                                            setValue={setValue}
                                            req='Falta especificar la fecha de baja médica'
                                            defaultValue={datosUsuario.CausaPagoDirecto.PrestacionFechaBajaMedica ? datosUsuario.CausaPagoDirecto.PrestacionFechaBajaMedica : ''}
                                        />
                                    </div>
                                    {/*
                                    <InputMultiRadio
                                        errors={errors}
                                        register={register}
                                        opciones={["Si", "No"]}
                                        labels={["Sí", "No"]}
                                        name={'IT.PrestacionBajaUltimos6Meses'}
                                        id={'IT.PrestacionBajaUltimos6Meses'}

                                        label='¿Ha estado de baja médica en los seis meses anteriores por el mismo motivo?'
                                    />
                                    */}

                                    <InputContigenciasPagoDirecto errors={errors} register={register} name={'CausaPagoDirecto.Contingencias'}
                                        defaultValue={datosUsuario.CausaPagoDirecto.Contingencias ? datosUsuario.CausaPagoDirecto.Contingencias.toString() : ''}
                                    />
                                    <InputCausaDelPago
                                        errors={errors}
                                        register={register}
                                        setValue={setValue}
                                        watchCodigoCausa={watchCausaPago}
                                        idCode={'CausaPagoDirecto.CausaPago'}
                                        idDescription={'CausaPagoDirecto.CausaPagoDescripcion'}
                                        req='Es Necesario elegir una Opcion'
                                        label='Causa del Pago Directo'
                                        defaultValue={datosUsuario.CausaPagoDirecto.CausaPago ? datosUsuario.CausaPagoDirecto.CausaPago.toString() : ''}
                                    />
                                </FieldsetContainer>
                            </div>
                        </div>
                    </AccordionForm>




                    {
                        //DESACTIVADO HASTA SABER COMO SE VA A HACER
                    }
                    {/* 
                    <div className="row" style={{ display: 'none' }}>
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Hijos/as a Cargo (solamente para baja médica de EC-ANL cuando causa pago directo por extinción de la relación laboral)" disabled={false}>
                                <ArrayFieldHijosACargo control={control} name={'HijosACargo'} errors={errors} register={register} watch={watch} setValue={setValue} />
                            </FieldsetContainer>
                        </div>
                    </div>
                    */}




                    <AccordionForm title={'Datos Bancarios'} expandedInit={false} nombreError='DatosBancarios'>
                        <div className="row">
                            <div className="col-xl-12">
                                <FieldsetContainer legend="Datos Bancarios" disabled={false}>
                                    <InputIBAN
                                        id='DatosBancarios.SolicitanteIBAN'
                                        name='DatosBancarios.SolicitanteIBAN'
                                        defaultValue={datosUsuario.DatosBancarios.SolicitanteIBAN ? datosUsuario.DatosBancarios.SolicitanteIBAN : ''}
                                        placeholder=''
                                        xl={12}
                                        md={12}
                                        errors={errors}
                                        register={register}

                                        req='Falta el Número de Cuenta Bancaria'

                                    />
                                </FieldsetContainer>
                            </div>
                        </div>
                        <div className="row" style={{ display: 'none' }}>
                            <div className="col-xl-12">
                                <FieldsetContainer legend="Simulación de la Prestacion" disabled={false}>
                                    <div>SIMULACION DE LA PRESTACION A IMPLEMENTAR</div>
                                </FieldsetContainer>
                            </div>
                        </div>
                    </AccordionForm>






                    {/* 
                    <AccordionForm title={'Declaracion de Situacion de Actividad'} expandedInit={false} nombreError='DeclaracionActividad'>
                        <div className="row">
                            <div className="col-xl-12">
                                <FieldsetContainer legend="" disabled={false}>
                                    <div>
                                        <label className="input-block__label">Soy titular de un establecimiento mercantil, industrial o de otra naturaleza:</label>
                                        <YesNoRadioButtons
                                            yesId="DeclaracionActividad.titularidadYes"
                                            noId="DeclaracionActividad.titularidadNo"
                                            label='¿Es titular de la actividad?'
                                            name="DeclaracionActividad.titularidad"
                                            errors={errors}

                                            register={register}
                                        />
                                    </div>
                                    <div style={{ display: disableTitularidad ? 'block' : 'none' }}>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <FieldsetContainer legend="Dicho establecimiento tiene su domicilio social en" disabled={false}>
                                                    <InputPattern
                                                        id="DeclaracionActividad.EstablecimientoCalle"
                                                        name="DeclaracionActividad.EstablecimientoCalle"
                                                        label="Calle, Via, Calleja, Avenida, etc."
                                                        placeholder=""

                                                        xl={6}
                                                        md={6}
                                                        errors={errors}
                                                        register={register}


                                                    />
                                                    <div className="col-xl-6">
                                                        <Province
                                                            setValue={setValue}
                                                            errors={errors}
                                                            register={register}
                                                            watchProvinceCode={watchProvinceCode}
                                                            watchProvinceDescription={watchProvinceDescription}
                                                            idCode="DeclaracionActividad.EstablecimientoProvincia"
                                                            idDescription="DeclaracionActividad.EstablecimientoProvincia_Descripcion"
                                                        />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <TestCommunity
                                                            errors={errors}
                                                            register={register}
                                                            setValue={setValue}
                                                            idCode="DeclaracionActividad.EstablecimientoComunidadCodigo"
                                                            idDescription="DeclaracionActividad.EstablecimientoComunidadDescripcion"
                                                            filterByProvince={true}
                                                            watchProvinceCode={watchProvinceCode}
                                                            watchCommunityCode={watchCommunityCode}
                                                            watchCommunityDescription={watchCommunityDescription}
                                                        />
                                                    </div>
                                                    <InputPattern
                                                        id="DeclaracionActividad.ActividadEconomica"
                                                        name="DeclaracionActividad.ActividadEconomica"
                                                        label="Cuya Actividad económica"
                                                        placeholder=""

                                                        xl={6}
                                                        md={6}
                                                        errors={errors}
                                                        register={register}


                                                    />
                                                    <InputPattern
                                                        id="DeclaracionActividad.CNAE2009"
                                                        name="DeclaracionActividad.CNAE2009"
                                                        label="CNAE-2009"
                                                        placeholder=""
                                                        maxLength={6}
                                                        xl={6}
                                                        md={6}
                                                        errors={errors}
                                                        register={register}


                                                    />
                                                </FieldsetContainer>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-xl-12">
                                                <FieldsetContainer legend="Y que durante la situacion de incapacidad temporal / riesgo, la actividad queda gestionada por: " disabled={false}>

                                                    <div className="input-block--radio-check__group d-flex flex-wrap flex-column align-items-start">
                                                        <label htmlFor="DeclaracionActividad.opcion1" className="input-block--radio-check__label ml-4">
                                                            <span className="text">Familiar</span>
                                                            <input
                                                                type="radio"
                                                                name="DeclaracionActividad.gestionActividad"
                                                                id="DeclaracionActividad.opcion1"
                                                                value="Familiar"
                                                                {...register("DeclaracionActividad.gestionActividad")}
                                                            />
                                                        </label>
                                                        <label htmlFor="DeclaracionActividad.opcion2" className="input-block--radio-check__label ">
                                                            <span className="text">Empleado</span>
                                                            <input
                                                                type="radio"
                                                                name="DeclaracionActividad.gestionActividad"
                                                                id="DeclaracionActividad.opcion2"
                                                                value="Empleado"
                                                                {...register("DeclaracionActividad.gestionActividad")}
                                                            />
                                                        </label>
                                                        <label htmlFor="DeclaracionActividad.opcion3" className="input-block--radio-check__label ">
                                                            <span className="text">Otra Persona</span>
                                                            <input
                                                                type="radio"
                                                                name="DeclaracionActividad.gestionActividad"
                                                                id="DeclaracionActividad.opcion3"
                                                                value="Otro"
                                                                {...register("DeclaracionActividad.gestionActividad")}
                                                            />
                                                        </label>
                                                        <label htmlFor="DeclaracionActividad.opcion4" className="input-block--radio-check__label ">
                                                            <span className="text">Cese temporal o definitivo de la actividad durante la situación de incapacidad temporal/riesgo durante el embarazo o durante la
                                                                lactancia natural del titular del establecimiento</span>
                                                            <input
                                                                type="radio"
                                                                name="DeclaracionActividad.gestionActividad"
                                                                id="DeclaracionActividad.opcion4"
                                                                value="Cese"
                                                                {...register("DeclaracionActividad.gestionActividad")}
                                                                defaultChecked
                                                            />
                                                        </label>
                                                    </div>

                                                    <div className="mb-2" style={{ display: disableGetionActividad ? 'block' : 'none' }} >
                                                        <FieldsetContainer legend=" " disabled={false}>
                                                            <InputPattern
                                                                id="DeclaracionActividad.GestionadoNombreApellidos"
                                                                name="DeclaracionActividad.GestionadoNombreApellidos"
                                                                label="Nombres y Apellidos"
                                                                placeholder=""

                                                                xl={12}
                                                                md={12}
                                                                errors={errors}
                                                                register={register}


                                                            />
                                                            <InputPattern
                                                                id="DeclaracionActividad.GestionadoNumeroSeguridadSocial"
                                                                name="DeclaracionActividad.GestionadoNumeroSeguridadSocial"
                                                                label="Número de afiliación a la Seguridad Social"
                                                                placeholder=""

                                                                xl={6}
                                                                md={6}
                                                                errors={errors}
                                                                register={register}


                                                            />
                                                            <InputNIE
                                                                id="DeclaracionActividad.GestionadoNIE"
                                                                name="DeclaracionActividad.GestionadoNIE"
                                                                label="NIE/NIF/DNI/Pasaporte"
                                                                placeholder=""
                                                                watch={watch}
                                                                xl={6}
                                                                md={6}
                                                                errors={errors}
                                                                register={register}
                                                                maxLength={20}

                                                            />
                                                            <div style={{ display: disableGetionActividadFamiliar ? 'block' : 'none' }}>
                                                                <InputPattern
                                                                    id="DeclaracionActividad.GestionadoParentesco"
                                                                    name="DeclaracionActividad.GestionadoParentesco"
                                                                    label="Parentesco"
                                                                    placeholder=""

                                                                    xl={12}
                                                                    md={6}
                                                                    errors={errors}
                                                                    register={register}


                                                                />
                                                            </div>
                                                        </FieldsetContainer>


                                                    </div>



                                                </FieldsetContainer>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: disableTitularidad ? 'none' : 'block' }}>
                                        <InputPattern
                                            id="DeclaracionActividad.DeclaracionRegimenEspecial"
                                            name="DeclaracionActividad.DeclaracionRegimenEspecial"
                                            label="Por el contrario declara que se encuentra incluido en el Régimen Especial de Trabajadores por Cuenta Propia o Autónomos en razón de la actividad económica de"
                                            placeholder=""

                                            xl={12}
                                            md={12}
                                            errors={errors}
                                            register={register}


                                        />
                                    </div>


                                </FieldsetContainer>
                            </div>
                        </div>
                    </AccordionForm>
                    */}





                    { !dataUser?.Documentacion ?
                        <AccordionForm title={'Archivos a Adjuntar'} expandedInit={false} nombreError='Archivo' >
                        <div className='paragraph small text-secondary  mt-4'>Con la firma de la presente solicitud, declaro bajo mi responsabilidad que son ciertos todos los datos que consigno en la misma y quedo enterado de la
                            obligación de comunicar a la Mutua cualquier variación de los datos en ella expresados, que pudieran producirse durante la percepción de la prestación
                            de Incapacidad Temporal.</div>
                        <div className="row">
                            <div className="col-xl-12">
                                
                                    {/* <label className="input-block__label mb-2 mt-4">Archivos a Adjuntar para trabajadores por cuenta ajena</label> */}

                                    {/* {[0, 1, 2, 3].map(index => (
                                        <InputArchivos

                                            key={`Archivo.${index}`}
                                            name={`Archivo.${index}`}
                                            id={`Archivo.${index}`}
                                            label={`Archivo de Ejemplo ${index}`}
                                            placeholder={''}
                                            xl={12}
                                            md={12}
                                            doc={doc}
                                        />
                                    ))} */}

                                    {
                                        solicitudPagoDirectoEjemplo?.Documentacion?.map((doc, index) => (
                                            <InputArchivos

                                                key={`Archivo.${index}`}
                                                name={`Archivo.${index}`}
                                                id={`Archivo.${index}`}
                                                label={``}
                                                placeholder={''}
                                                xl={12}
                                                md={12}
                                                doc={doc}
                                            />
                                        ))
                                    }

                                    {/* <label className="input-block__label mb-2 mt-4">El Certificado de Empresa se deberá adjuntar en los siguientes casos con la siguiente información:</label>
                                    <label className="input-block__label ml-3 mb-3"> 1. Si la causa del pago directo del apartado de esta solicitud es por extinción de la relación laboral y la causa de su baja médica del apartado 2 es
                                        EC o ANL, certificado de empresa con los 180 últimos días cotizados en su empresa o empresas anteriores</label>
                                    <label className="input-block__label ml-3 mb-3"> 2. Para el resto de supuestos de pago directo: certificado de empresa del mes anterior a la baja.</label>
                                    <label className="input-block__label ml-3 mb-3"> 2.1. Representantes de comercio: justificante de pago de las cotizaciones a la Seguridad Social de los tres meses anteriores a su baja médica, así como
                                        certificación de la Tesorería General de la Seguridad Social de estar al corriente.</label>
                                    <label className="input-block__label ml-3 mb-3"> 2.2. Artistas y profesionales taurinos: declaración de actividades (TC 4/6) y justificantes de actuaciones (TC 4/5), que no hayan sido presentados en la
                                        Tesorería General de la Seguridad Social.</label>
                                    <label className="input-block__label ml-3 mb-3"> 2.3. Agrarios cuenta Ajena: certificado de empresa del mes anterior a la baja, si la baja es por Enfermedad Comun certificado de los 12 meses anteriores al
                                        mes de la baja. .</label>
                                    <label className="input-block__label ml-3 mb-3"> 2.4. Sistema Especial de Hogar: certificado del empleador o empleadores, del mes anterior a la baja médica</label>

                                    <InputUpload
                                        name={'Archivo.ArchivoCertificadoEmpresa'}
                                        id={'Archivo.ArchivoCertificadoEmpresa'}
                                        label={'Certificado de Empresa'}
                                        placeholder={''}
                                        errors={errors}
                                        register={register}
                                        xl={12}
                                        md={12}
                                        doc={undefined}
                                        setError={setError} />
                                    <label className="input-block__label mb-2 mt-4">Trabajadores por cuenta propia</label>
                                    
                                    <InputUpload
                                        name={'Archivo.ArchivoTrabajadorPropiaJustificantePagoCotizaciones'}
                                        id={'Archivo.ArchivoTrabajadorPropiaJustificantePagoCotizaciones'}
                                        label={' Justificante de pago de sus cotizaciones de los dos últimos meses anteriores al mes de la baja médica.'}
                                        placeholder={''}
                                        errors={errors}
                                        register={register}
                                        xl={12}
                                        md={12}
                                        doc={undefined}
                                        setError={setError} />



                                    <ArrayFieldUploads control={control} register={register} name={'Archivo.ArchivoOtros'} errors={errors} setError={setError} />
                                     */}
                             
                            </div>
                        </div>
                    </AccordionForm>
                    : ''
                    }
                    

                    {/* BOTON DE ENVIAR */}
                    <button className='btn-box btn-accent  mt-3 mb-3' disabled={aceptarPagoDir} type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>
                    {
                        errorDirectPaymentFormIt ? <div className='paragraph error  mb-4'>{errorDirectPaymentFormIt}</div> : ''
                    }


                    <div className='paragraph small text-secondary  mb-4'>En cumplimiento de lo establecido en la normativa sobre de Protección de Datos de Carácter Personal, Solimat Mutua Colaboradora con la Seguridad Social nº 72 le informa que sus datos de
                        carácter personal serán objeto de tratamiento, con la finalidad del mantenimiento y cumplimiento de la relación. La base jurídica que legitima el tratamiento de sus datos es el
                        cumplimiento de una obligación legal. Sus datos serán conservados de forma indefinida en nuestra entidad para atender a las posibles responsabilidades derivadas del tratamiento. Usted
                        podrá revocar el consentimiento prestado, así como ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento, o portabilidad, en su caso,
                        remitiendo un escrito a la siguiente dirección: Solimat, Mutua Colaboradora con la Seguridad Social nº 72, C/ Berna, 1, 45003, Toledo, adjuntando su NIF. Si considera que sus
                        derechos no han sido debidamente atendidos, puede contactar con nuestro delegado de protección de datos lopd@solimat.com o interponer una reclamación frente a la Agencia
                        Española de Protección de Datos.</div>

                    <div className='paragraph small text-secondary  mb-4'>En el caso de que no haya presentado toda la documentación que le hemos solicitado, dispone de un plazo de diez días para presentarla. Transcurrido dicho plazo, si no lo aporta, se le
                        tendrá por desistido, de acuerdo con lo previsto en el art. 68 de la Ley 39/2015, de 1 de octubre, del Procedimiento Administrativo Común de las Administraciones Públicas. (B.O.E. 2 de
                        octubre de 2015).</div>
                </div>


            </FormProvider >

        </>
    )
}

export default SolicitudPagoDirectoIncapacidadTemporal;


