import React, { useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import axios from 'axios';
import { guid } from '@solimat/solimat-web-endpoint/lib/user/dto';
import { useCheckTypeNotification } from "hooks/useCheckTypeNotification";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useUpdateNotification from "./useUpdateNotification";
import useDecode from "hooks/useDecode";


const BASE_URL = process.env.REACT_APP_NOTIFICACIONES_PATH || ""; //"https://solimat-dev-notification.azurewebsites.net"
//const URL_NEGOTIATE = BASE_URL + "/api/user/" || ""; //"https://solimat-dev-notification.azurewebsites.net/api/negotiate";

interface INotificationUpdate {
  usuarioId: guid,
  notificacionId: guid
}
interface INotificationRequest {
  id: guid,
  usuarioId: guid,
  titulo: string,
  mensaje: string,
  tipo: NotificacionTipo,
  scope: NotificacionScope,
  tipoPresatcion?: number,
  prestacionId?: guid,
  tipoContingencia?: number,
  tipoComponente?: number,
  componenteId?: guid
}
enum NotificacionScope {
  CITAS,
  PRESTACIONES_PACIENTES
}
enum NotificacionTipo {
  SIGNALR
}
interface ISignalRNotification {
  Id: guid,
  Text: string,
  Timestamp: Date
}

interface IToastNotification {
  notificationData?: INotificationRequest,
  signalRData?: ISignalRNotification
}

const ToastNotification: React.FC<IToastNotification> = ({ notificationData, signalRData }) => {
  const [redirectPath, setRedirectPath] = useState<any>({ pathname: '' });
  const { updateNotification } = useUpdateNotification();
  const { checkTypeNotification } = useCheckTypeNotification()
  useEffect(() => {
    let routeTo = checkTypeNotification(notificationData);
    if (routeTo !== undefined) {
      setRedirectPath(routeTo);
    }
    else {
      redirectPath.pathname = '/notificaciones';
      setRedirectPath(redirectPath);
    }
  }, [notificationData])

  const handleUpdateNotification = () => {
    redirect();
  }

  const redirect = () => {
    if (redirectPath?.pathname !== undefined && redirectPath.pathname !== '') {
      return window.location.href = redirectPath.pathname;
    }
  }

  return (
    <a className="toast-notification"
      onClick={handleUpdateNotification}
    >
      <div className="icon-bell"></div>
      <div>
        <h4 className="title">Nueva notificacion</h4>
        <p className="text">{notificationData?.titulo ?? signalRData?.Text}</p>
      </div>
    </a>
  );

}

toast.configure();

/* signalR hook */

const useSignalR = () => {
  const [newNotification, setNewNotification] = useState({});
  const [connectionState, setConnectionState] = useState(false);
  const [connectionHub, setConnectionHub] = useState<any>({});
  const protocol = new signalR.JsonHubProtocol();
  const negociate = (userId: guid) => {
    let url = `${BASE_URL}/api/user/${userId}/negotiate`;
    return axios.post(url, {});
  }
  let options: any = null;
  let connection: signalR.HubConnection = null;
  let mess: any[] = [];
  
  let data = {
    username: '',
    defaultgroup: 'AzureSignalR',
    checked: false,
    newMessage: '',
    messages: mess,
    myConnectionId: '',
    ready: false
  };


  const handleStartSignalR = async (token: any) => {
    negociate(token.extension_id)
      .then((res) => {
        let info = res.data;
        options = {
          skipNegotiation: false,
          accessTokenFactory: () => {
            return res.data.accessToken;
          }
        }
        connection = new signalR.HubConnectionBuilder()
          .withUrl(info.url, options)
          .withHubProtocol(protocol)
          .withAutomaticReconnect()
          .configureLogging(signalR.LogLevel.Information)
          .build();

        setConnectionHub(connection);

        addMessage('connecting...');

        connection.start()
          .then((resp) => {
            if (connection && connection.state === signalR.HubConnectionState.Connected) {
              // connection.on('newConnection', onNewConnection);
              connection.on('onUserMessage', onUserMesasge);
              // connection.on('onGroupMessage', onGroupMesasge);
              // connection.on('onConnected', onConnected);
              setConnectionState(true);
              // onNewConnection(connection);
              addMessage('connected!');

              if (token?.extension_roles) {
                let roles = JSON.parse(token.extension_roles) as string[];
                addMessage(`user has ${roles.length} roles!`);
                roles.map(g => connection.send("JoinUserToGroup", token.extension_id, g));
              }
            }
          })
          .catch((err) => { console.error(err) });

      }).catch((err) => { console.error(err) });

    // if(role === 3 ) {
    //   connection = new signalR.HubConnectionBuilder().withUrl(`${BASE_URL}/api`, options)
    //   .withHubProtocol(protocol)
    //   .withAutomaticReconnect()
    //   .configureLogging(signalR.LogLevel.Information)
    //   .build();

    //   connection.start()
    //   setConnectionState(true)
    //   setConnectionHub(connection);


    //   connection.on('notificationPatientOrdered' + tokenResponse.extension_id + ':' + role, (res:any) => {
    //     setNewNotification(res);
    //     toast(<ToastNotification
    //         notificationData={res} 
    //       />,
    //       { position: "top-right", 
    //         autoClose: false, 
    //         hideProgressBar: true
    //       }
    //     )
    //   });
    // }
   
  }
  function addMessage(message: any) {
    // data.messages.unshift(message);
  };
  let counter = 0;

  function onNewConnection(message: any) {
    if (!!message) {
      data.myConnectionId = message.connectionId ?? message.connection?.connectionId;
      if (!!data.myConnectionId) {
        let newConnectionMessage = {
          Id: counter++,
          Text: `${data.myConnectionId}esta conectado`
        };
        addMessage(newConnectionMessage);
      }
    }
  }
  function onUserMesasge(message: any) {
    // addMessage(message);
    toast(<ToastNotification
      notificationData={message}
      signalRData={message}
    />,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true
      }
    )
    setNewNotification(message)

  }
  function onGroupMesasge(message: any) {
    addMessage(message);
    toast(<ToastNotification
      notificationData={message}
      signalRData={message}
    />,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true
      }
    )
  }
  function onConnected(message: any) {
    addMessage(message);
  }
  const handleDisconnectSignalR = (connection: any) => {
  
    if (Object.keys(connection).length !== 0) {
      connection.stop();
     
    }

    setConnectionState(false);

  }

  return {
    newNotification,
    connectionState,
    handleStartSignalR,
    handleDisconnectSignalR,
    connectionHub
  };
}

export default useSignalR;