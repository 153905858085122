import React, { useState, useEffect, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import '../../Accordion/Accordion.scss';
import down from '../../../../img/icons/Delta/down.png';
import { error } from 'console';

interface IProps {
    children: React.ReactNode;
    title: string;
    expandedHide?: boolean;
    expandedInit: boolean;
    nombreError?: string;
    
}

const AccordionForm: React.FC<IProps> = ({ children, title, expandedHide, expandedInit, nombreError }) => {
    const { formState: { errors } } = useFormContext();
    const [accordionState, setAccordionState] = useState<boolean>(expandedInit);

    useEffect(() => {
        if (typeof expandedHide === 'boolean') {
            setAccordionState(false);
            
        }
    }, [expandedHide]);

    const handleToggleAccordionState = () => {
        setAccordionState(!accordionState);
    };
    
    useEffect(() => {
       
        if (Object.keys(errors).some(key => key.startsWith(nombreError))) {
          setAccordionState(true);
        }
      },[errors[nombreError]]);
    


    return (
        <div className={`Accordion ${accordionState ? 'expanded' : ''}`}>
            <p className={`accordion-header-block `}>
                <button onClick={handleToggleAccordionState}>
                    <span className="d-flex justify-content-between align-items-center">
                        <span className="header-text d-flex align-items-center">
                            <span className="e-icons MT_Preview mr-2"></span>
                            <span className="text">{title}</span>
                        </span>
                        <span className="header-icon e-icons e-down-arrow2">
                            <img src={down} alt="down" className='iconDown' />
                        </span>
                    </span>
                </button>
            </p>
            <div className={`accordion-item`}>
                <div className={`${accordionState ? '' : 'd-none'}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AccordionForm;
