import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useGetDirectPaymentFrmsItByUser = () => {
  const [loadingGetDirectPaymentFormsItByUser, setLoadingGetDirectPaymentFormsItByUser] = useState(false);
  const [successGetDirectPaymentFormsItByUser, setSuccessGetDirectPaymentFormsItByUser] = useState(false);
  const [errorGetDirectPaymentFormsItByUser, setErrorGetDirectPaymentFormsItByUser] = useState(null);
  const [dataGetDirectPaymentFormsItByUser, setDataDirectPaymentFormsItByUser] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apirecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )

  
  //GET
  const getDirectPaymentFormsItByUser = async (userId: string, _cb?:(data:any)=>void) => {
    setLoadingGetDirectPaymentFormsItByUser(true);
    setSuccessGetDirectPaymentFormsItByUser(false);
    setErrorGetDirectPaymentFormsItByUser(null);

    apirecord.getDirectPaymentFormsItByUser(userId).then((res: any) => {
      setLoadingGetDirectPaymentFormsItByUser(false);
      setSuccessGetDirectPaymentFormsItByUser(true);
      setDataDirectPaymentFormsItByUser(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }

    }).catch((err:any) => {
      console.log(err)
      setLoadingGetDirectPaymentFormsItByUser(false);
      setErrorGetDirectPaymentFormsItByUser(err)
    })
  }


  return {
    getDirectPaymentFormsItByUser,
    loadingGetDirectPaymentFormsItByUser,
    successGetDirectPaymentFormsItByUser,
    errorGetDirectPaymentFormsItByUser,
    dataGetDirectPaymentFormsItByUser
  }

}

export default useGetDirectPaymentFrmsItByUser;