import {useState} from 'react';
import {record} from '@solimat/solimat-web-endpoint';

const useGetInfoDirectPaymentFormIt = () => {
  const [loadingGetInfoDirectPaymentFormIt, setLoadingGetInfoDirectPaymentFormIt] = useState(false);
  const [successGetInfoDirectPaymentFormIt, setSuccessGetInfoDirectPaymentFormIt] = useState(false);
  const [errorGetInfoDirectPaymentFormIt, setErrorGetInfoDirectPaymentFormIt] = useState(null);
  const [infoDirectPaymentFormIt, setInfoDirectPaymentFormIt] = useState<null|any>(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(BASE_URL, FUNCTIONS_KEY);
 
  const getInfoDirectPaymentFormIt = (userId:string, _cb?:(data:any)=>void) => {
    setLoadingGetInfoDirectPaymentFormIt(true);
    setSuccessGetInfoDirectPaymentFormIt(false);
    apiRecord.getInfoDirectPaymentFormIt(userId)
      .then((res) => {       
        setInfoDirectPaymentFormIt(res.data);
        setLoadingGetInfoDirectPaymentFormIt(false);
        setSuccessGetInfoDirectPaymentFormIt(true);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err)=> {
        console.log(err)
        setLoadingGetInfoDirectPaymentFormIt(false);
        setErrorGetInfoDirectPaymentFormIt(err)
      })
  }


  return {
    infoDirectPaymentFormIt,
    getInfoDirectPaymentFormIt,
    loadingGetInfoDirectPaymentFormIt,
    successGetInfoDirectPaymentFormIt,
    errorGetInfoDirectPaymentFormIt
    };
}

export default useGetInfoDirectPaymentFormIt;