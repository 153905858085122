import React, { useState, useEffect } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { FieldValues, UseFormRegister } from "react-hook-form";
interface IInputProps {
  name: string;
  id: string;
  label?: string;
  placeholder?: string;
  errors: any;
  register: UseFormRegister<FieldValues>;
  defaultValue?: any;
  setValue?: any;
  getDayWeek?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date; 
  req?: string;
  disableDate?: boolean
}

const InputDate: React.FC<IInputProps> = ({
  name,
  id,
  label,
  placeholder,
  errors,
  register,
  defaultValue,
  getDayWeek,
  setValue,
  minDate,
  maxDate,
  req,
  disableDate
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    defaultValue ? defaultValue : null 
  );
if (defaultValue) {
}
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (getDayWeek && date) {
      getDayWeek(date);
    }
  };


  return (
    <div className={`container-inputdate input-block ${id} ${errors && errors[name]?.message ? "error" : ""}`}>
      {label && <label htmlFor={id} className="input-block__label">{label}</label>}
      <DatePickerComponent
        format='dd-MM-yyyy'
        name={name}
        id={id}
        change={(event) => handleDateChange(event.value)}
        value={selectedDate}
        // locale='es' 
        firstDayOfWeek={1}
        {...register(name, { required: req })}
        min={minDate}
        max={maxDate}
        disabled={disableDate}
      />
      {errors && errors[name]?.message && <p className="error m-0">{errors && errors[name]?.message}</p>}
      {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
          <>
            <p className="error m-0 ">
              {errors?.[name.split('.')[0]][name.split('.')[1]]?.message}
            </p>
          </>

          : ''}
    </div>
  );
};

export default InputDate;
