import React, { useContext, useEffect, useState } from 'react';
import { AutoCompleteComponent, FilteringEventArgs } from '@syncfusion/ej2-react-dropdowns';
import useUtils from 'services/utils/useUtils';
import masterDataUtils from 'services/utils/masterDataUtils';
import { MasterDataContext } from 'components/pages/Delta/Delta';
import { useFormContext } from 'react-hook-form';
import Fuse from 'fuse.js';

interface IInputProps {
  errors: any;
  register: any;
  setValue: any;
  handleChange?: (e: any) => void;
  idDescription: string;
  idCode: string;
  watchProvinceCode?: string;
  filterByProvince?: boolean;
  watchCommunityCode: string;
  watchCommunityDescription: string;
  required?: string;
  disabled?: boolean;
}

const TestCommunity: React.FC<IInputProps> = ({
  errors,
  register,
  setValue,
  idDescription,
  idCode,
  watchProvinceCode,
  filterByProvince,
  watchCommunityCode,
  watchCommunityDescription,
  required,
  disabled
}) => {
  const [data, setData] = useState<any[]>([]);
  const { getAllCity, getCityByProvince, onlyDigitsWithMaxLength, pasteOnlyDigits } = useUtils();

  const masterDataContext = useContext(MasterDataContext);
  const { checkAndUpdate } = masterDataUtils(masterDataContext);

  const masterData = masterDataContext?.masterData;

  let itemKey: string = 'city';

  const formContext = useFormContext();
  const getValues = formContext?.getValues;

  useEffect(() => {
    if (!filterByProvince) checkAndUpdate(itemKey, data, getAllCity, setData);
  }, [masterData]);

  const itemsData: any[] = filterByProvince ? data : data?.filter(x => x.Codigo.startsWith(watchProvinceCode));
  const fields: object = { value: 'Descripcion2' };

  function onFiltering(e: FilteringEventArgs) {
    let options: Object = {
      keys: ['Descripcion2'],
      includeMatches: true,
      findAllMatches: true
    };

    let fuse: any = new Fuse(itemsData, options);
    let result: any = fuse.search(e.text);
    let data: { [key: string]: Object; }[] = [];
    for (let i: number = 0; i < result.length; i++) {
      data.push(result[i].item as any);
    }
    e.updateData(data, undefined);

    let lists: any = document?.getElementById('community_popup')?.querySelectorAll('.e-list-item');
    if (lists) {
      highlightSearch(lists, result as any);
    }
  }

  function highlightSearch(listItems: Element[], result: any): void {
    if (result.length > 0) {
      for (let i: number = 0; i < listItems.length; i++) {
        let innerHTML: string = listItems[i].innerHTML;
        for (let j: number = result[i].matches[0].indices.length - 1; j >= 0; j--) {
          let indexes: number[] = result[i].matches[0].indices[j];
          innerHTML = innerHTML.substring(0, indexes[0]) + '<span class="e-highlight">' +
            innerHTML.substring(indexes[0], (indexes[1] + 1)) + '</span>' + innerHTML.substring(indexes[1] + 1);
          listItems[i].innerHTML = innerHTML;
        }
      }
    }
  }

  useEffect(() => {
    if (!watchProvinceCode || !filterByProvince || watchProvinceCode.length < 2) return;
    setData([]);
    getCityByProvince(watchProvinceCode).then((data) => {
      setData(data);
    });
  }, [watchProvinceCode]);

  useEffect(() => {
    if (!watchCommunityCode) return;
    setValueCommunityDescription();
  }, [data]);

  const handleChange = (args: any) => {
    if (data && args.value) {
      const codeValue = data?.find((item: any) => item.Descripcion2 === args.value);
      const inputID = idCode ? idCode : 'communityCode';
      if (codeValue) {
        setValue(inputID, codeValue ? codeValue.CodigoMunicipio : '');
      } else {
        setValue(inputID, '');
      }
    }
  };

  const setValueCommunityDescription = () => {
    if (data) {
      const descriptionValue = data?.find((item: any) => item?.CodigoMunicipio === watchCommunityCode);
      const inputID = idDescription ? idDescription : 'communityDescription';
      if (descriptionValue) {
        setValue(inputID, descriptionValue ? descriptionValue.Descripcion2 : '');
      } else {
        setValue(inputID, '');
      }
    }
  };

  const handleInputBlur = () => {
    const value = getValues(idCode);
    if (value === "") {
      setValue(idDescription, "");
    }
  };

  const handleAutoCompleteBlur = () => {
    const value = getValues(idDescription);
    if (value === "") {
      setValue(idCode, "");
    }
  };

  const handleAutoCompleteChange = (args: any) => {
    handleChange(args);
    if (!args.value) {
      const inputID = idCode ? idCode : 'communityCode';
      setValue(inputID, '');
    }
  };

  if (data?.length) {
    var isDisabled: boolean = filterByProvince && (!watchProvinceCode || watchProvinceCode === "");

    if(disabled === true) isDisabled = true;

    var title: string = isDisabled ? 'Seleccione primero una provincia' : '';
    const inptIdCode = `${idCode ? idCode : "communityCode"}`;
    const inptIdDescription = `${idDescription ? idDescription : "communityDescription"}`;
    return (
      <>
        <div className="auto-complete-component--syncfusion common-form">
          <div className={`input-block ${errors && errors[inptIdCode]?.message || errors && errors[inptIdDescription]?.message ? 'error' : ''}`}>
            <p className="input-block__label">{"Municipio"}</p>
            <div id='autocustom' className='control-pane'>
              <div className='control-section'>
                <input type="text"
                  id={inptIdCode}
                  name={inptIdCode}
                  className=""
                  {...register(inptIdCode)}
                  maxLength={5}
                  autoComplete="off"
                  onKeyDown={(e) => { onlyDigitsWithMaxLength(e, getValues(inptIdCode), 5) }}
                  onPasteCapture={(e) => pasteOnlyDigits(e)}
                  onBlur={handleInputBlur}
                  disabled={true}
                  title={title}
                />
                <div id='custom-filtering'>
                  <AutoCompleteComponent
                    ignoreAccent={true}
                    ignoreCase={true}
                    id={inptIdDescription}
                    name={inptIdDescription}
                    dataSource={itemsData}
                    filtering={onFiltering}
                    fields={fields}
                    placeholder="Municipio"
                    autofill={true}
                    {...register(inptIdDescription, { required: required })}
                    change={handleAutoCompleteChange}
                    onBlur={handleAutoCompleteBlur}
                    enabled={!isDisabled}
                    title={title}
                  />
                </div>
              </div>
            </div>
            {errors &&
              <>
                <p className="error m-0">
                  {errors[inptIdCode]?.message}
                </p>
                <p className="error m-0">
                  {errors[inptIdDescription]?.message}
                </p>
              </>
            }
            {errors?.[inptIdDescription.split('.')[0]] && errors?.[inptIdDescription.split('.')[0]][inptIdDescription.split('.')[1]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
        <>
          <p className="error m-0 ">
            {errors?.[inptIdDescription.split('.')[0]][inptIdDescription.split('.')[1]]?.message}
          </p>
        </>

        : ''}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <p className="input-block__label">{"Municipio"}</p>
        <div className="input-block-structure--loading"></div>
      </div>
    );
  }
};

export default TestCommunity;
