import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import PageMenu from 'components/blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import FieldsetContainer from 'components/blocks/Delta/Inputs/Containers/FieldsetContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/blocks/Loader/Loader';

export const SolicitudPrestacionEconomicaCeseActividad: React.FC = () => {

    const location = useLocation();
    const title = "Solicitud de Prestación Económica por Cese de Actividad"
    const breadcrumbs = ["Solicitud de Prestación Económica por Cese de Actividad"];
    const formMethods = useForm();
    const { register, handleSubmit, setError, formState: { errors }, setValue, watch, control } = formMethods;

    const onSubmit = async (data: any) => {
        
        console.log(data);
    };


    return (
        <>
            <PageMenu title={title} breadcrumbs={breadcrumbs}>
                <div className="btns-header-container">
                    <Link to={`/inicio`}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </Link>
                </div>
            </PageMenu>
            <FormProvider {...formMethods}>
                <div className="container form--simple-styled" id="formDeltawithoutleave">
                    <div className="row">
                        <div className="col-xl-12">
                            <FieldsetContainer legend="Datos de la empresa" disabled={false}>
                                <div className="col-xl-4 col-md-6">

                                </div>

                            </FieldsetContainer>
                        </div>
                    </div>

                    {/* BOTON DE ENVIAR */}
                    <button className='btn-box btn-accent mt-3 mb-3' type="submit" onClick={handleSubmit(onSubmit)}>
                        Enviar
                    </button>
                </div>


            </FormProvider>

        </>
    )
}

export default SolicitudPrestacionEconomicaCeseActividad;
