import React, { useState, useCallback } from "react";
import { management } from "@solimat/solimat-web-endpoint";

function useGetMyManagement() {
  const BASE_URL = process.env.REACT_APP_MANAGEMENT_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_MANAGEMENT_KEY || "";
  const apiManagement = new management.ManagementService(
    BASE_URL,
    FUNCTIONS_KEY
  );
  const [loadinGetMyManagement, setLoadingGetMyManagement] = useState(false);
  const [successGetMyManagement, setSuccessGetMyManagement] = useState(false);
  const [errorGetMyManagement, setErrorGetMyManagement] = useState(null);
  const [dataMyManagement, setdataMyManagement] = useState({});
  const [activeManagements, setActiveManagements] = useState([]);
  const [groupsActiveManagements, setgroupsActiveManagements] = useState([]);
  const [groupsGeneralManagements, setgroupsGeneralManagements] = useState([]);
  const [generalManagements, setGeneralManagements] = useState([]);
  const [formulariosAutorizaciones, setFormulariosAutorizaciones] = useState([]);
  const [documentosAutorizaciones, setDocumentosAutorizaciones] = useState([]);

  const getMyManagement = useCallback((_cb?: (data: any) => void) => { 
    setLoadingGetMyManagement(true);
    setErrorGetMyManagement(null);
    const promise = apiManagement.getManagement();// cabiar para datos mock
    promise
      .then((res: any) => {
        setSuccessGetMyManagement(true);
        setLoadingGetMyManagement(false);
        setActiveManagements(res.data.Activos?.Gestiones);
        setGeneralManagements(res.data.Generales?.Gestiones);
        setgroupsGeneralManagements(res.data.Generales?.Grupos);
        setgroupsActiveManagements(res.data.Activos?.Grupos);
        setFormulariosAutorizaciones(res.data.Autorizaciones.FormulariosAutorizaciones);
        setDocumentosAutorizaciones(res.data.Autorizaciones.DocumentosAutorizaciones);
        setdataMyManagement(res.data && res.data);
        if (typeof _cb === "function") {
          _cb(res.data);
        }
      })
      .catch((err) => {
        console.error("getCurrentRequestTransaction error: ", err);
        setErrorGetMyManagement(err);
        setLoadingGetMyManagement(false);
      });
  }, [apiManagement]);

  return {
    dataMyManagement,
    generalManagements,
    groupsGeneralManagements,
    activeManagements,
    groupsActiveManagements,
    getMyManagement,
    loadinGetMyManagement,
    successGetMyManagement,
    errorGetMyManagement,
    formulariosAutorizaciones,
    documentosAutorizaciones,
  };
}

export default useGetMyManagement;
