import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import useCheckRecordStatusAndTypes from 'hooks/useCheckRecordStatusAndTypes';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Controller, FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';
import Delete from "../../../../img/icons/svg/botones/delete.png";
import JSZip from 'jszip';

interface Documentacion {
    Id: string;
    DocumentoDigitalId?: number;
    TipoDocumento?: number;
    NombrePortal?: string;
    NombreStorage?: string;
    Container?: string;
    Extension?: string;
    FechaPresentacion?: Date;
    Uri?: string;
    SasUri?: string;
    Observaciones?: string;
    Nota?: string;
}


interface IInputProps {
    name: string,
    id: string,
    label: string,
    placeholder: string,
    readonly?: boolean,
    defaultValue?: string
    disabled?: boolean
    maxLength?: number,
    value?: any,
    req?: string
    xl?: number
    md?: number
    doc: Documentacion
}

const InputArchivos: React.FC<IInputProps> = ({ name, id, label, disabled, req, xl, md, doc }) => {

    const [ErrorMensaje, setErrorMensaje] = useState<string>('');
    const { control, formState: { errors }, register } = useFormContext();
    const { checkFormStatusRD, checkFormStatusDocumentsRD } = useCheckRecordStatusAndTypes();
    const [currentObservations, setCurrentObservations] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalSuccessfully, setShowModalSuccessfully] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: { files: File[]; documentName: string } }>({});

    const removeFile = (documentId: string, fileIndex: number) => {
        console.log(name)
        setSelectedFiles(prevSelectedFiles => {
            const updatedFiles = prevSelectedFiles[documentId]?.files.filter((_, index) => index !== fileIndex);

            if (updatedFiles.length === 0) {
                const { [documentId]: removed, ...rest } = prevSelectedFiles;
                return rest;
            }

            return {
                ...prevSelectedFiles,
                [documentId]: {
                    ...prevSelectedFiles[documentId],
                    files: updatedFiles
                }
            };
        });
    };



    const validateFile = (value: any) => {



        if (req) {
            if (!value || value.length === 0) {
                setErrorMensaje(req);
                return false;
            }
        }


        if (value.length > 0) {
            const file = value[0];
            const fileSize = file.size;
            const fileName = file.name;
            //AÑADIR MAS EXTENSIONES SI FUERA NECESARIO
            /* EXTENSIONES PERMITIDAS '.jpg', '.jpeg', '.png', '.gif', '.pdf', '.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.zip', '.7z', '.txt', '.csv', '.odt', '.ods', '.webp'  */
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.zip', '.7z', '.txt', '.csv', '.odt', '.ods', '.webp'];
            const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

            if (fileSize > 100 * 1024 * 1024) {
                setErrorMensaje("El archivo supera los 100MB");
                return false;
            }

            if (!allowedExtensions.includes(fileExtension)) {
                setErrorMensaje("Extensión no permitida para estos archivos");
                return false;
            }


            setErrorMensaje("")
            return true;
        }
    };

    const handleFileChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        documentId: string,
        documentName: string
    ) => {
        const allFiles = Array.from(e.target.files as FileList);

        const prohibitedExtensions = ['exe', 'bat', 'cmd', 'sh', 'msi', 'vbs', 'scr', 'js', 'jar', 'html', 'htm', 'mhtml', 'ods', 'jfif'];

        const validFiles = allFiles.filter(file => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            return !prohibitedExtensions.includes(fileExtension || '');
        });

        const invalidFiles = allFiles.filter(file => !validFiles.includes(file));

        if (invalidFiles.length > 0) {
            alert('Algunos archivos tienen extensiones no permitidas. Por favor, seleccione otros archivos.');
            e.target.value = '';
            return;
        }

        if (validFiles.length > 0) {
            setSelectedFiles(prevSelectedFiles => {
                const existingFiles = prevSelectedFiles[documentId]?.files || [];
                return {
                    ...prevSelectedFiles,
                    [documentId]: {
                        files: [...existingFiles, ...validFiles],
                        documentName,
                    },
                };
            });
        }
    };

    const handleShowModal = (observations: string) => {
        setCurrentObservations(observations);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentObservations('');
    };
    const handleShowModalsuccessfully = () => {

        setShowModalSuccessfully(true);
    };

    const handleCloseModalsuccessfully = () => {
        setShowModalSuccessfully(false);
        setCurrentObservations('');
    };

    return (
        <>
            <div className="container-incopatibilidad mb-2" key={id}>
                <fieldset className={`fieldset-container`}>

                    <legend className="col-12">
                        <span className="legend-text">{label}</span>
                    </legend>
                    {/* <h6 className='incompatibility-status'>{`Estado:  ${'PROTEGIDO'}`}</h6> */}
                    <div key={id}>
                        <div className="container-documentos" >
                            <div className='col-sm-12 col-md-12 col-lg-3'>
                                <div className="column-name">Documento solicitado</div>
                                <div className="name-and-document-container">

                                    <p className='name-doc-incopatibilidad-rd'>{doc.NombrePortal ? `${doc.NombrePortal}` : 'Sin Datos'}</p>
                                    {doc.SasUri &&
                                        <TooltipComponent content="Archivo adjunto">
                                            <a
                                                href={doc.SasUri}
                                                rel={doc.NombrePortal}
                                                // target="_blank"
                                                className="link-bold"
                                            >
                                                <span className="file-icon-blue"></span>
                                            </a>
                                        </TooltipComponent>
                                    }
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-3'>
                                <div className="column-name">Fecha Presentación</div>
                                <p className='presentation-date-rd'>{doc?.FechaPresentacion ? moment(doc.FechaPresentacion).format('DD-MM-YYYY') : 'Sin Datos'}</p>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-3'>
                                <div className="column-name">Estado Documento</div>
                                <div className='name-and-icon-information'>
                                    <p>{'PROTEGIDO'}</p>
                                    {doc.Observaciones &&
                                        <TooltipComponent content="Observaciones">
                                            <span className='information-icon-orange' onClick={() => handleShowModal(doc.Observaciones)}></span>
                                        </TooltipComponent>
                                    }

                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-3'>
                                <div className="column-name">Notas</div>
                                <p className='presentation-date-rd'>{doc?.Nota ? doc?.Nota : 'Sin Especificaciones'}</p>
                            </div>
                            
                            <div className='col-sm-12 col-md-12 m-2 col-lg-12'>
                                <Controller
                                    name={`${id}`}
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <>
                                            {/* <label htmlFor={`files-${id}`} className={`btn-communication btn-load-file-rd btn-disabled `}>
                                                Adjuntar archivo
                                                <input
                                                    type="file"
                                                    id={`files-${id}`}
                                                    className="input-file input-file-rd "
                                                    disabled
                                                />
                                            </label> */}
                                            <label htmlFor={`files-${id}`} className={`btn-communication btn-load-file-rd ${false ? 'btn-disabled' : ''} `}>
                                                Adjuntar archivo
                                                <input
                                                    type="file"
                                                    id={`files-${id}`}
                                                    className="input-file input-file-rd "
                                                    {...register(name + '.file', { required: 'Debe adjuntar el siguiente tipo de Archivo: ' + doc?.NombrePortal, onChange: (e) => handleFileChange(e, doc.Id, doc.NombrePortal) })}
                                                    multiple
                                                />
                                                <input
                                                    type="text"
                                                    id={`tipo-file-${id}`}
                                                    {...register(name + '.tipo', { value: doc?.TipoDocumento })}
                                                    hidden
                                                />
                                                <input
                                                    type="text"
                                                    id={`tipo-file-${id}`}
                                                    {...register(name + '.nombrePortal', { value: doc?.NombrePortal })}
                                                    hidden
                                                />
                                                <input
                                                    type="text"
                                                    id={`tipo-file-${id}`}
                                                    {...register(name + '.id', { value: doc?.Id })}
                                                    hidden
                                                />
                                            </label>
                                        </>
                                    )}
                                />

                            </div>

                            {selectedFiles[doc.Id] && (
                                <div className='container-file-info'>
                                    <p className='selected-file-text-rd'>
                                        {`(${selectedFiles[doc.Id].documentName})`}
                                    </p>
                                    <div className='container-files-list-rd'>
                                        {selectedFiles[doc.Id].files.map((file, index) => (
                                            <div key={index} className='container-name-file-rd'>
                                                <p className='name-file-rd'>
                                                    {file.name}
                                                </p>
                                                <img
                                                    src={Delete}
                                                    alt="borrar"
                                                    className="remove-file-btn"
                                                    onClick={() => removeFile(doc.Id, index)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                        </div>

                    </div>

                </fieldset>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
                    <span className='information-icon-orange big'></span>
                    <Modal.Title>

                        <h2 className='title-obsevaciones-rd'>Observaciones</h2>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='body-modal-observaciones-rd'>
                    <p className='text-body-modal-observaciones-rd'>{currentObservations ? currentObservations : 'No Datos'}</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* successfully OR ERROR */}
         
            

            {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.file?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
                <>
                    <p className="error m-0 ">
                        {errors?.[name.split('.')[0]][name.split('.')[1]]?.file?.message}
                    </p>
                </>

                : ''}
            {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
                <>
                    <p className="error m-0 ">
                        {errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message}
                    </p>
                </>

                : ''}


        </>
    )
}

export default InputArchivos;



/* 


 PROCESAMIENTO DE ERRORES ANTIGUO GUARDADO EN COMENTARIO POR SI ES NECESARIO
 NO DEBERÍA FUNCIONAR BIEN

           {errors[name] && errors ?
                <p className="error m-0 ">
                    {ErrorMensaje}
                </p>
                : ''}

            {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.message ?
                <>
                    <p className="error m-0 ">
                        {ErrorMensaje}
                    </p>
                </>

                : ''}

            {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
                <>
                    <p className="error m-0 ">
                        {ErrorMensaje}
                    </p>
                </>
                : ''}


*/

