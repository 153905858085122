import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageMenu from '../../blocks/PageMenu/PageMenu';
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import useGetDirectPaymentFrmsItByUser from "services/record/useGetDirectPaymentFormsItByUser";
import useGetInfoDirectPaymentFormIt from "services/record/useGetInfoDirectPaymentFormIt";
import useDecode from "hooks/useDecode";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Sort,
    Inject,
} from "@syncfusion/ej2-react-grids";
import SolicitudPagoDirectoIncapacidadTemporal from "./SolicitudPagoDirectoIncapacidadTemporal";
import Loader from "components/blocks/Loader/Loader";
import LoaderMin from "components/blocks/Loader/LoaderMin";

const SolicitudPagoDirectoIncapacidadTemporalGrid = () => {
    const { dataGetDirectPaymentFormsItByUser, getDirectPaymentFormsItByUser, successGetDirectPaymentFormsItByUser } = useGetDirectPaymentFrmsItByUser()
    const { getInfoDirectPaymentFormIt, infoDirectPaymentFormIt, loadingGetInfoDirectPaymentFormIt } = useGetInfoDirectPaymentFormIt()
    const title = 'Solicitud de Pago Directo Incapacidad Temporal'
    const history = useHistory();
    const tokenResponse = useDecode("token");
    const userId = tokenResponse && tokenResponse.extension_id;
    const [arrData, setArrData] = useState(null)
    const [dataUser, setDataUser] = useState(null);
    const [loadInfo, setLoadInfo] = useState('')
    const [sortingOptions] = useState({
        columns: [{ field: "created", direction: "Descending" }],
    });
    let pageOptions = {
        pageSize: 10,
        pageSizes: ["5", "10", "15", "20", "Todos"],
    };
    const rowSelected = (args) => {
        const selectedData = args.data;
        setDataUser(selectedData);

    };
    useEffect(() => {

        getDirectPaymentFormsItByUser(userId);
    }, [])

    const handleGetInfoDirectPaymentFormIt = () => {
        setLoadInfo('initProcces')
        getInfoDirectPaymentFormIt(userId, setDataUser)
        if (dataUser) {
            setLoadInfo('endProcces')
        }
    }

        useEffect(() => {
            if (successGetDirectPaymentFormsItByUser) {
                setArrData(dataGetDirectPaymentFormsItByUser);
            }
        }, [dataGetDirectPaymentFormsItByUser]);

        return (
            <div className="container bg-#EEEEEE">
                <PageMenu title={title} >
                    <div className="btns-header-container">
                        <button onClick={() => history.goBack()}>
                            <img src={IconArrowGray} alt="icon arrow right" />
                        </button>
                    </div>
                </PageMenu>
                <GridComponent
                    dataSource={arrData && arrData}
                    allowPaging={true}
                    allowSorting={true}
                    rowSelected={rowSelected}
                    pageSettings={pageOptions}
                    sortSettings={sortingOptions}
                    enablePersistence={true}
                >
                    <ColumnsDirective>
                        <ColumnDirective
                            field="RazonSocial"
                            headerText="Razon Social"
                            width="100"
                            textAlign="Center"
                        />
                        <ColumnDirective
                            field="EstadoFormulario"
                            headerText="Estado"
                            width="100"
                            textAlign="Center"
                        />
                        <ColumnDirective
                            field="FechaAceptacionSolicitud"
                            headerText="Fecha aceptación"
                            type="date"
                            format="dd/MM/yyyy"
                            width="100"
                        />
                        <ColumnDirective
                            field="FechaBajaMedica"
                            headerText="Fecha Baja"
                            type="date"
                            format="dd/MM/yyyy"
                            width="100"
                        />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort]} />
                </GridComponent>
                <div className="delta-buttons c-right">
                    <button className="btn-box btn-accent" onClick={() => handleGetInfoDirectPaymentFormIt()}>
                        Generar Formulario
                    </button>
                </div>
                {dataUser &&
                    <SolicitudPagoDirectoIncapacidadTemporal dataUser={dataUser} ></SolicitudPagoDirectoIncapacidadTemporal>
                    //  <LoaderMin activeLoader label="Cargando Datos "></LoaderMin>
                     
                     }

            </div>
        );
    };
    export default SolicitudPagoDirectoIncapacidadTemporalGrid;
