import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useUtils from 'services/utils/useUtils'

interface IInputProps {
    errors: any,
    register: any,
    defaultValue?: string
    defaultDescripcion?: string
    name: string
    xl?: number
    md?: number
    disabled?: boolean
}

const InputRegimenSeguridadSocial: React.FC<IInputProps> = ({ errors, register, defaultDescripcion, defaultValue, name, xl, md, disabled }) => {
    const [data, setData] = useState<any[] | null>([])
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const [descripcion, setDescripcion] = useState('');
    const { setValue } = useFormContext();
    const {
        getRegimenesSeguridadSocialPagoDirecto
    } = useUtils();

    useEffect(() => {
        handleGetAllSocialSecurityRegimen()
        setValue(`${name}Descripcion`, data[0]?.Descripcion);
    }, [])
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(`${name}Descripcion`, event.target.selectedOptions[0].text);
        setSelectedValue(event.target.value);
    };

    const handleGetAllSocialSecurityRegimen = async () => {
        const response = await getRegimenesSeguridadSocialPagoDirecto();
        setData(response);

        setSelectedValue(defaultValue ? defaultValue : response[0]?.Codigo);
        setValue(`${name}`, defaultValue ? defaultValue : response[0]?.Codigo);
        setValue(`${name}Descripcion`, defaultDescripcion ? defaultDescripcion : response[0]?.Descripcion);
    }

    return (
        <div className={`col-xl-${xl} col-md-${md} col-12`}>


            <fieldset className={`input-block input-block--code ${errors && errors[name]?.message ? 'error' : ''}`}>
                <legend className="input-block--code__legend">Régimen Seguridad Social</legend>
                <select
                    name={name}
                    id={name}  {...register(name)}
                    onChange={(e) => handleSelectChange(e)}
                    value={selectedValue}
                    disabled={disabled}
                >
                    
                        {!data?.length ? null : data?.map((item: any) => {
                            return (
                                <option key={item.Codigo} value={item.Codigo}>{item.Descripcion}</option>
                            )
                        })}
                                            

                </select>
                <input type="hidden" {...register(`${name}Descripcion`)} />
                {errors &&
                    <p className="error m-0">
                        {errors[name]?.message}
                    </p>
                }
            </fieldset>
        </div>
    )
}

export default InputRegimenSeguridadSocial;