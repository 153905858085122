import React, { useState } from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';

interface IInputProps {
    name: string,
    id: string,
    label: string,
    placeholder: string,
    errors: any,
    register: UseFormRegister<FieldValues>,
    setError: any,
    readonly?: boolean,
    defaultValue?: string
    disabled?: boolean
    maxLength?: number,
    value?: any,
    isvalidateNumber?: boolean,
    isvalidateNumberPostalCode?: boolean,
    req?: string
    xl?: number
    md?: number
}

const InputUpload: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, register, readonly, defaultValue, disabled, maxLength, req, xl, md, setError }) => {

    const [ErrorMensaje, setErrorMensaje] = useState<string>('');
    const validateFile = (value: any) => {



        if (req) {
            if (!value || value.length === 0) {
                setErrorMensaje(req);
                return false;
            }
        }


        if (value.length > 0) {
            const file = value[0];
            const fileSize = file.size;
            const fileName = file.name;
            //AÑADIR MAS EXTENSIONES SI FUERA NECESARIO
            /* EXTENSIONES PERMITIDAS '.jpg', '.jpeg', '.png', '.gif', '.pdf', '.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.zip', '.7z', '.txt', '.csv', '.odt', '.ods', '.webp'  */
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.zip', '.7z', '.txt', '.csv', '.odt', '.ods', '.webp'  ]; 
            const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

            if (fileSize > 100 * 1024 * 1024) {
                setErrorMensaje("El archivo supera los 100MB");
                return false;
            }

            if (!allowedExtensions.includes(fileExtension)) {
                setErrorMensaje("Extensión no permitida para estos archivos");
                return false;
            }


            setErrorMensaje("")
            return true;
        }
    };



    return (
        <div className={`col-xl-${xl} col-md-${md} mb-3`}>

            <label className="form-label">{label}</label>
            <input type="file" multiple className=" form-control" id={id} {...register(name, { validate: validateFile })} />


            {ErrorMensaje && ErrorMensaje.length > 0 ?
                <p className="error m-0 ">
                    {ErrorMensaje}
                </p>
                : ''}

        </div>
    )
}

export default InputUpload;



/* 


 PROCESAMIENTO DE ERRORES ANTIGUO GUARDADO EN COMENTARIO POR SI ES NECESARIO
 NO DEBERÍA FUNCIONAR BIEN

           {errors[name] && errors ?
                <p className="error m-0 ">
                    {ErrorMensaje}
                </p>
                : ''}

            {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.message ?
                <>
                    <p className="error m-0 ">
                        {ErrorMensaje}
                    </p>
                </>

                : ''}

            {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.[name.split('.')[2]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
                <>
                    <p className="error m-0 ">
                        {ErrorMensaje}
                    </p>
                </>
                : ''}


*/

