import React, { createContext, useState, useEffect } from 'react';
import useSignalR from "../services/notifications/useSignalR";
import useGetAllNotificationsByUser from "../services/notifications/useGetAllNotificationsByUser";
import useDecode from "../hooks/useDecode";

const SignalRContext = createContext();

const SignalRContextProvider = ({ children }) => {
  const {
    newNotification,
    connectionState,
    connectionHub,
    handleStartSignalR,
    handleDisconnectSignalR,
  } = useSignalR();

  const [notification, setNotification] = useState({});
  const [allNotifications, setAllNotifications] = useState([]);
  const [isConnectedSignalR, setisConnectedSignalR] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [changeRole, setChangeRole] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isTokenReady, setIsTokenReady] = useState(false); 
  const token = localStorage.getItem('token'); 
  const decodedToken = useDecode("token");
  const tokenResponse = isTokenReady ? decodedToken : null; 
  const userId = tokenResponse?.extension_id;
  const roleSelected = tokenResponse?.extension_roles;

  const {
    getAllUserNotifications,
    allUserNotifications,
    loadingGetAllUserNotifications,
  } = useGetAllNotificationsByUser();

// confirmar si el token esta disponible 
  useEffect(() => {
    if (!token) {
      const interval = setInterval(() => {
        const updatedToken = localStorage.getItem('token');
        if (updatedToken) {
          clearInterval(interval); 
          setIsTokenReady(true);
        }
      }, 100); 
      return () => clearInterval(interval);
    } else {
      setIsTokenReady(true);
    }
  }, [token]);


  useEffect(() => {
    if (isTokenReady && !isConnectedSignalR) {
      if (userId) {
        handleStartSignalR(tokenResponse); 
        setisConnectedSignalR(true);
      } else {
        console.warn('Paciente no identificado ');
      }
    }
    return () => {
      if (isConnectedSignalR) {
        handleDisconnectSignalR(connectionHub);
      }
    };
  }, [isTokenReady, userId, isConnectedSignalR]);

  // Configuración de roles
  useEffect(() => {
    if (roleSelected) {
      try {
        const parsedRoles = JSON.parse(roleSelected);
        const role = parsedRoles[0];
        setUserRole(role);
      } catch (error) {
        console.error('Error parsing roles:', error);
      }
    }
  }, [roleSelected]);

  // Manejo de notificaciones
  useEffect(() => {
    setNotification(newNotification);
  }, [newNotification, isUpdated]);

  // Cambio de roles
  useEffect(() => {
    if (changeRole && connectionState) {
      handleDisconnectSignalR(connectionHub);
      setChangeRole(false);
    }
  }, [userRole, changeRole, connectionState]);

  const handleGetAllNotifications = () => {
    if (userId) {
      getAllUserNotifications(userId);
    } else {
      console.warn('La identificación de usuario no está disponible para recuperar notificaciones.');
    }
  };

  const handleSetUserAndChangeRole = (userRole, change) => {
    setUserRole(userRole);
    setChangeRole(change);
  };

  const handleAbortSignalR = () => {
    handleDisconnectSignalR(connectionHub);
  };

  return (
    <SignalRContext.Provider
      value={{
        notification,
        allUserNotifications,
        allNotifications,
        loadingGetAllUserNotifications,
        getAllUserNotifications,
        handleGetAllNotifications,
        setUserRole,
        setChangeRole,
        handleSetUserAndChangeRole,
        handleAbortSignalR,
        setIsUpdated,
        isUpdated,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export { SignalRContext, SignalRContextProvider };
