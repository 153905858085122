import React from 'react'
import { useLocation } from "react-router-dom";
import SolicitudPagoDirectoIncapacidadTemporalGrid from 'components/pages/ManagementNewForms/SolicitudPagoDirectoIncapacidadTemporalGrid';
const PageRenderNewForms = () => {
    const location = useLocation();
    const { id } = location.state || {};
    switch (id) {
        case 5:

            return (
                <div>

                    <SolicitudPagoDirectoIncapacidadTemporalGrid/>

                </div>
            )

        default:
            break;
    }

}

export default PageRenderNewForms

