import React from 'react'
import { useHistory } from "react-router-dom";
import SolicitudPagoDirectoIncapacidadTemporal from './SolicitudPagoDirectoIncapacidadTemporal'
import PageMenu from 'components/blocks/PageMenu/PageMenu'
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
const HistorialSolicitudPagoDirectoIncapacidadTemporal = () => {
    const history = useHistory()
    return (
        <div>
            <PageMenu title={'Solicitud de Pago Directo Incapacidad Temporal'}>

                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>

            </PageMenu>
            <SolicitudPagoDirectoIncapacidadTemporal>
            </SolicitudPagoDirectoIncapacidadTemporal>


        </div>
    )
}

export default HistorialSolicitudPagoDirectoIncapacidadTemporal