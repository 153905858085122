import React, { useState } from 'react';
import { record } from "@solimat/solimat-web-endpoint";

const useCreatetDirectPaymentFormIt = () => {
  const [loadingDirectPaymentFormIt, setLoadingCreateSolicitudPagoDirecto] = useState(false);
  const [successDirectPaymentFormIt, setSuccessCreateSolicitudPagoDirecto] = useState(false);
  const [errorDirectPaymentFormIt, setErrorCreateSolicitudPagoDirecto] = useState(null);
  const [dataDirectPaymentFormIt, setDataSolicitudPagoDirecto] = useState(null);

  const BASE_URL = process.env.REACT_APP_PRESTACIONES_PACIENTE_PATH || "";
  const FUNCTIONS_KEY = process.env.REACT_APP_PRESTACIONES_PACIENTE_KEY || "";
  const apiRecord = new record.RecordService(
    BASE_URL,
    FUNCTIONS_KEY
  )



  //CREATE
  const createDirectPaymentFormIt = async (userId:string ,prestacionId: string, data: any ,_cb?:(data:any)=>void) => {
    setLoadingCreateSolicitudPagoDirecto(true);
    setSuccessCreateSolicitudPagoDirecto(false);
    setErrorCreateSolicitudPagoDirecto(null);

    apiRecord.newDirectPaymentFormIt(userId, prestacionId, data).then((res: any) => {
      setLoadingCreateSolicitudPagoDirecto(false);
      setSuccessCreateSolicitudPagoDirecto(true);
      setDataSolicitudPagoDirecto(res.data);
      if (typeof _cb === "function") {
        _cb(res.data);
      }

    }).catch((err:any) => {
      console.log(err)
      setLoadingCreateSolicitudPagoDirecto(false);
      setErrorCreateSolicitudPagoDirecto(err.response.data)
    })
  }




  return {
    createDirectPaymentFormIt,
    loadingDirectPaymentFormIt,
    successDirectPaymentFormIt,
    errorDirectPaymentFormIt,
    dataDirectPaymentFormIt
  }

}

export default useCreatetDirectPaymentFormIt;