import React from 'react';
import { FieldValues, RefCallBack, UseFormRegister, useForm, useFormContext } from 'react-hook-form';

interface IInputProps {
    name: string,
    id: string,
    label: string,
    placeholder: string,
    errors: any,
    register: any,
    readonly?: boolean,
    defaultValue?: boolean
    disabled?: boolean
    functionOnChange?: (value: boolean) => void;
    col?: number
    xl?: number
    md?: number
    justify?: string
}

const InputChkBox: React.FC<IInputProps> = ({ name, id, label, placeholder, errors, register, readonly, defaultValue, disabled, xl, md, col, functionOnChange, justify }) => {

    return (<>

        <div className={` col-xl-${xl} col-md-${md} col-${col} align-items-center d-flex justify-content-${justify}`}>
            <div className={`form-check d-flex justify-content-center  ${errors && errors[name]?.message ? 'error' : ''}`}>

                <input
                    className='form-check mr-2'
                    type="checkbox"
                    name={name}
                    id={id}
                    checked={defaultValue}
                    placeholder={placeholder}
                    {...register(name)}
                    readOnly={readonly || false}
                    disabled={disabled || false}
                    onChange={(e) => functionOnChange?.(e.target.checked)}
                />
                <label className="input-block__label" htmlFor={id}>{label}</label>
                {errors && errors ?
                    <p className="error m-0 ">
                        {errors && errors[name]?.message}
                    </p>
                    : ''}
                {errors?.[name.split('.')[0]] && errors?.[name.split('.')[0]][name.split('.')[1]]?.message ? //procesamiento de error cuando se usa dentro de un ArrayField
                    <>
                        <p className="error m-0 ">
                            {errors?.[name.split('.')[0]][name.split('.')[1]]?.message}
                        </p>
                    </>

                    : ''}
            </div>
        </div>
    </>

    )
}

export default InputChkBox;